/*
* Librairie d'animation on scroll. Cette librairie est fait pour
* fonctionner avec la custom scrollbar.
* (peut être tweaker pour fonctionner sans la scrollbar custom)
*
* - Ajax ready
*/

// **---------------------------------------------------**
// -------------------------------------------------------
// Global variables BEGIN
var ScrollItems = [];
// Global variables END
// **---------------------------------------------------**
// -------------------------------------------------------


// **---------------------------------------------------**
// -------------------------------------------------------
// Object BEGIN
function ScrollItem(e, inview, delay, bp) {
	/*
	* constructor
	*/
	this.element = e;
	this.inview = inview;
	this.delay = delay;
	this.bp = bp;
}
// Object END
// **---------------------------------------------------**
// -------------------------------------------------------


// **---------------------------------------------------**
// -------------------------------------------------------
// Prototype BEGIN

// init
ScrollItem.prototype.init = function() {
	/*
	* get all the elements
	*/
	var elements = document.getElementsByClassName('js-watchScroll');

	/*
	* loop through the array and create an object with all it's properties
	*/
	for (var i = 0; i < elements.length; i++) {
		e = elements[i];

		/*
		* get the element properties
		*/
		inview = currentlyVisible(e);

		delay = null;
		bp = null;
		if (e.dataset.delay) {
			value = e.dataset.delay;

			// check if string contains a breakpoint (return true if the substring is present)
			if (value.indexOf('-') !== -1) {
				value_copy = value.split('-');
				bp = value_copy[0];
				delay = value_copy[1];

				if (bp == 'sm' && screenWidth < 768) {
					bp = null;
				}

				if (bp == 'md' && screenWidth < 991) {
					bp = null;
				}

				if (bp == 'lg' && screenWidth < 1199) {
					bp = null;
				}
			}
		}

		/*
		* create the object
		*/
		obj = new ScrollItem(e, inview, delay, bp);

		/*
		* push the created object in the array
		*/
		ScrollItems.push(obj);

		/*
		* init the css transform propertie on the element
		*/
		if (!e.classList.contains('is-notVisible')) {
			e.classList.add('is-notVisible');
		}
	}
}

// watch
ScrollItem.prototype.watch = function() {
	for (var i = ScrollItems.length - 1; i >= 0; i--) {
		e = ScrollItems[i];
		block = e.element;

		/*
		* check if the element is in view
		*/
		isInview = currentlyVisible(block);

		// if true, update its property
		if (isInview) {
			e.inview = true;

			/*
			* update the object
			*/
			ScrollItem.prototype.update(i);
			ScrollItem.prototype.remove(i);
		} else {
			e.inview = false;
		}
	}
}

// update
ScrollItem.prototype.update = function(i) {
	/*
	* assign some variables
	*/
	e = ScrollItems[i];
	block = e.element;

	/*
	* check if the element has delay
	*/

	delay = e.delay;
	delayTime = 0;
	if (delay != null) {
		delayTime = delay * 150;
		// check if there is a breakpoint
		bp = e.bp
		if (bp != null) {
			// console.log(bp);
		}
	}

	/*
	* update the element
	*/
	ScrollItem.prototype.show(block, delayTime, bp)
}

// show
ScrollItem.prototype.show = function(block, delayTime, bp) {
	setTimeout(function() {
		block.classList.remove('is-notVisible');
		block.classList.add('is-visible');
	}, delayTime)
}

// add
// ScrollItem.prototype.add = function(index) {
// 	ScrollItems.splice(index, 1);
// }

// remove
ScrollItem.prototype.remove = function(i) {
	ScrollItems.splice(i, 1);
}

// reset
ScrollItem.prototype.reset = function() {
	ScrollItems = [];
}

// Prototype END
// **---------------------------------------------------**
// -------------------------------------------------------


/*
* watchscroll.js est comme une librairie standard (scrollcontent.js) qui vérifie si
* un élement est visible à l'écran, mais sur le crack. La différence est qu'on peut
* définir des delays par block d'animation en utilisant des data-delay,
* et ca, de facon responsive!.
*
* Pour "watch" un élément, voici la facon de faire:
* 	<div class="js-watchScroll">
* 	</div> 
*
* Imaginez deux colonnes côte à côte. On veut faire fade-in la colonne de gauche
* en premier et ensuite la colonne de droite. Normalement on pourrait faire ca avec
* des delays CSS hardcodé pour cette rangée là, mais ca devient vite
* un bordel (yes Shalwin et Stratera). Donc pour mieux gérer sans faire de css, on déclare
* les delays avec des data-delay:
*	<div class="row">
*		<div class="col-6 js-watchScroll">
*		</div>
*
*		<div class="col-6 js-watchScroll" data-delay="1">
*		</div>
*	</div>
*
* Comment faire pour avoir un delay seulement en md + ? EZ!
*	<div class="row">
*		<div class="col-6 js-watchScroll">
*		</div>
*
*		<div class="col-6 js-watchScroll" data-delay="md-1">
*		</div>
*	</div>
*
* Chaques incrémentations ajoute un delay de 0.15ms à l'ajout de la class "is-visible"
* sur le div "watcher"
* 
*/


/*
*** Available prototype
*
* ScrollItem.prototype.init();
*
** i: item index
*
* ScrollItem.prototype.watch();
* ScrollItem.prototype.update(i)
* ScrollItem.prototype.show()
* ScrollItem.prototype.remove()
* ScrollItem.prototype.reset()
*/


/*
* @TODO
* - Prototype add single element
* - Prévoir une facon de rapide d'enlever la dépendance à la custom scrollbar.
*	Présentement on dépend de la function currentlyVisible() pour vérifier
*	si un element est visible à l'écran. Il suffirait de faire une function qui
*	functionne comme scrollcontent.js pour vérifier si un élement est visible
*/

